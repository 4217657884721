import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

const Home = React.lazy(() => import('./pages/Home'));
const Experiences = React.lazy(() => import('./pages/Experiences'));
const Destinations = React.lazy(() => import('./pages/Destinations'));
const About = React.lazy(() => import('./pages/About'));
const Rentals = React.lazy(() => import('./pages/Rentals'));
const RentalDetails = React.lazy(() => import('./pages/RentalDetails'));
const Retreats = React.lazy(() => import('./pages/Retreats'));
const Contact = React.lazy(() => import('./pages/Contact'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const TermsConditions = React.lazy(() => import('./pages/TermsConditions'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));

const Navigation = React.lazy(() => import('./components/Navigation'));
const ContactFloat = React.lazy(() => import('./components/ContactFloat'));
const WhatsappFloat = React.lazy(() => import('./components/WhatsappFloat'));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navigation />
        <ContactFloat />
        <WhatsappFloat />
        {/* <MusicPlayer /> */}
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/destinations' component={Destinations} />
          <Route path='/rentals' component={Rentals} />
          <Route path={`/rental/:name`} component={RentalDetails} />
          <Route path='/experiences' component={Experiences} />
          <Route path='/retreats' component={Retreats} />
          <Route path='/about' component={About} />
          <Route path='/contact' component={Contact} />
          <Route path='/terms-and-conditions' component={TermsConditions} />
          <Route path='/privacy-policy' component={PrivacyPolicy} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
