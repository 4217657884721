import React, { useState } from 'react';
import './App.css';

import ClipLoader from "react-spinners/ClipLoader";


function Loader() {
    let [color] = useState("rgb(104, 81, 85);");

    return (
      <div className="loading">
        <ClipLoader color={color} loading={true}  size={150} />
      </div>
    );
}

export default Loader;
